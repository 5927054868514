import React from 'react';

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */

import { Play, PlayLarge } from '@components/icon/icon';

const PlayIcon = (props) => {
    return (
        <div className="play-icon">
           {props?.size && props?.size === 'large' ?  <PlayLarge /> : <Play /> }       
        </div>
    )
}

export default PlayIcon;